import Handsontable from "handsontable";

const headerAlignments = new Map([
  ["9", "htCenter"],
  ["10", "htRight"],
  ["12", "htCenter"]
]);


export const addClassesToRows = function (
  TD,
  row,
  column,
) {
  // Adding classes to `TR` just while rendering first visible `TD` element
  if (column !== 0) {
    return;
  }

  const parentElement = TD.parentElement;

  if (parentElement === null) {
    return;
  }

  // Add class to odd TRs
  if (row % 2 === 0) {
    Handsontable.dom.addClass(parentElement, "odd");
  } else {
    Handsontable.dom.removeClass(parentElement, "odd");
  }
};

export const drawCheckboxInRowHeaders = function drawCheckboxInRowHeaders(
  that,
  row,
  TH
) {
  const input = document.createElement("input");

  input.type = "checkbox";

  if (row >= 0 && that.getDataAtRowProp(row, "0")) {
    input.checked = true;
  }

  Handsontable.dom.empty(TH);

  TH.appendChild(input);
};

export function alignHeaders(
  that,
  column,
  TH
) {
  if (column < 0) {
    return;
  }

  if (TH.firstChild) {
    const alignmentClass = that.isRtl() ? "htRight" : "htLeft";

    if (headerAlignments.has(column.toString())) {
      Handsontable.dom.removeClass(
        TH.firstChild,
        alignmentClass
      );
      Handsontable.dom.addClass(
        TH.firstChild,
        headerAlignments.get(column.toString())
      );
    } else {
      Handsontable.dom.addClass(TH.firstChild, alignmentClass);
    }
  }
}