import Handsontable from "handsontable";
import Utils from "@/service/Utils";
export const xlsxStylesRenderer = (hotInstance,
    TD,
    row,
    column,
    prop,
    value,
    cellProperties) => {
  let font = cellProperties.font || {};
  if (font.bold) {
    TD.style.fontWeight = "bold";
  }
  if (font.italic) {
    TD.style.fontStyle = "italic";
  }
  if (font.height) {
    TD.style.fontSize = `${font.height/16}px`;
  }
  if (font.name) {
    TD.style.font = font.name; 
  }
  Handsontable.renderers.TextRenderer(hotInstance, TD, row, column, prop, value, cellProperties);
};

export const imageRenderer = (hotInstance,
  TD,
  row,
  column,
  prop,
  value,
  cellProperties) => { 
  Handsontable.renderers.BaseRenderer.apply(this, [hotInstance, TD, row, column, prop, value, cellProperties]);
    const img = document.createElement('img');
    img.src = `data:image/png;base64,${cellProperties.base64}`;
    // if (Array.isArray(cellProperties.size)) {
    //   img.width =  cellProperties.size[0];
    //   img.height = cellProperties.size[1];
    // };
    // img.addEventListener('mousedown', event => {
    //   event.preventDefault();
    // });
  TD.innerHTML = "";
  TD.appendChild(img);
  return TD;
};

export const qrcodeRenderer = (hotInstance,
  TD,
  row,
  column,
  prop,
  value,
  cellProperties) => { 
  Handsontable.renderers.BaseRenderer.apply(this, [hotInstance, TD, row, column, prop, value, cellProperties]);
  const cellValue = cellProperties.value || "";
  const resource = cellProperties.resource || "package";
  TD.innerHTML = "";
  if (cellValue) {
    const qrcode = document.createElement('div');
    const qr = new (window).QRCode(qrcode, {
      width: 128,
      height: 128,
    });
    const qrcodeContent = process.env.VUE_APP_BASE_URL + "#/" + Utils.toBase64(resource + "|" + cellValue.trim());
    qr.makeCode(qrcodeContent);
    TD.appendChild(qrcode);
  }
  return TD;
};

  export const handsontablePlugin = {
    registerRenderers: () => { 
      Handsontable.renderers.registerRenderer('xlsxStylesRenderer', xlsxStylesRenderer);
      Handsontable.renderers.registerRenderer('imageRenderer', imageRenderer);
      Handsontable.renderers.registerRenderer('qrcodeRenderer', qrcodeRenderer)
    }
}