//import {api} from "primevue/api";
const serializeOrders = function (orderEvent) {
    let orders = orderEvent.multiSortMeta;
    if (orderEvent.sortField != null && orderEvent.sortOrder != null) {
        orders = [{field:orderEvent.sortField, order: orderEvent.sortOrder}]
    }
    if (Array.isArray(orders)) {
        return orders.map(item => item.field + (item.order == 1 ?  " ASC" : " DESC")).join(",");
    }
    return null;
};
const serializeFilters = function (filterEvent) {
    let filters = {};
    let hasFilter = 0;
    for (let field in filterEvent?.filters) {
        let filter = filterEvent.filters[field];
        if (Array.isArray(filter.constraints)) {
            let constraints = filter.constraints.filter(item => {
                if (Array.isArray(item.value)) {
                    return item.value.length > 0;
                } else {
                    return item.value != null;
                }
            });
            if(constraints.length > 0) {
                hasFilter = 1;
                filters[field] = {operator: filter.operator, constraints};
            }
        }
    }
    return hasFilter ? encodeURIComponent(JSON.stringify(filters)): null;
};
// const filterMatchModeOptions = {
//     text: [api.FilterMatchMode.STARTS_WITH, api.FilterMatchMode.CONTAINS, api.FilterMatchMode.NOT_CONTAINS, api.FilterMatchMode.ENDS_WITH, api.FilterMatchMode.EQUALS, api.FilterMatchMode.NOT_EQUALS],
//     numeric: [api.FilterMatchMode.EQUALS, api.FilterMatchMode.NOT_EQUALS, api.FilterMatchMode.LESS_THAN, api.FilterMatchMode.LESS_THAN_OR_EQUAL_TO, api.FilterMatchMode.GREATER_THAN, api.FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
//     date: [api.FilterMatchMode.DATE_IS, api.FilterMatchMode.DATE_IS_NOT, api.FilterMatchMode.DATE_BEFORE, api.FilterMatchMode.DATE_AFTER]
// };

export {
    serializeOrders,
    serializeFilters,
    //filterMatchModeOptions
};