import http, { Service } from "./common";
import { serializeFilters } from '@/utils/params';
const resource = "package";
class PackageService extends Service {
  verify_create(data) {
    console.log(data);
    return [];
  }
  /*
  savePackages(listPackages) {
    return http.post(`/${resource}/list`,listPackages).then(response => response.data)
  }
  */
  async getUnshipped(options) {
    let url = `/${this.resource}/unshipped`;
    let params = this.createQueryParams(options);
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    const response = await http.get(url);
    if (response.status == 200) {
      return response.data;
    }
    return {
      content: [],
      totalElements: 0
    };
  }
  // async findUnshipped(options) {
  //   return http
  //     .post(`/${this.resource}/unshipped`, options)
  //     .then((response) => {
  //       return response.data || [];
  //     });
  // }
  async findAll(options) {
    return http.post(`/${this.resource}/findAll`, options).then((response) => {
      return response.data || [];
    });
  }
  async removeItem(itemId) {
    return http.delete(`/${this.resource}/removeReceiptItem/${itemId}`).then((response) => {
      return response.data || [];
    });
  }
  async getArrivingPackages(options) {
    const page = options?.page || 0;
    let url = `/${this.resource}/arriving?page=${page}`;
    if (options && options.size) {
      url = `${url}&size=${options.size}`;
    }
    if (options && options.country) {
      url = `${url}&country=${options.country}`;
    }
    return http.get(url).then((res) => res.data);
  }
  async getArrivedPackages(options) {
    const page = options?.page || 0;
    let url = `/${this.resource}/arrived?page=${page}`;
    if (options && options.size) {
      url = `${url}&size=${options.size}`;
    }
    if (options && options.country) {
      url = `${url}&country=${options.country}`;
    }
    return http.get(url).then((res) => res.data);
  }
  async getPrintCounter(ids) {
    let idsValue = ids.join(",");
    const res = await http.get(`/${resource}/printcounter?ids=${idsValue}`);
    return res.data;
  }
  async getPrintInfo(strIds) {
    const res = await http.get(`/${resource}/printinfo?ids=${strIds}`);
    return res.data;
  }
  async storePrintLog(type, ids) {
    let data = {
      type,
      ids: ids.split(","),
    };
    return http.post(`/${resource}/print`, data);
  }
  async storePackage(data) {
    const res = await http.put(`/${resource}/store`, data);
    return res;
  }
  async findByCode(code) {
    let filter = serializeFilters({
      filters: {
        packageCode: {
          operator: "and",
          constraints: [{matchMode: "equals", value: code}]
        }
      }
    });
    return this.getAll({filter});
  }
  async getLastEmsPackageCode() {
    return http.get(`/${resource}/lastemscode`);
  }
  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`);
  }
}

export default new PackageService(resource);
