<template>
  <div class="shipping-label grid">
    <label
      class="col-12"
      style="text-align: center; font-style: italic; font-weight: bold"
    >
      {{ $t("shipment.shipping_label_title") }}
    </label>
    <div class="col-4 xs:col-4 sm:col-4" style="text-align: center">
      <!-- <div style="font-weight: bold">Saigon Travel</div> -->
      <h5>Saigon Travel</h5>
    </div>
    <div
      class="col-8 xs:col-8 sm:col-8"
      style="text-align: center; font-weight: bold; font-size: 20px"
    >
      <label>{{ entity.packageCode }}</label>
    </div>
    <div class="col-4 section" style="text-align: center">
      <qrcode-vue :value="packageResource" :size="qrcodeSize" level="H" />
    </div>
    <div class="col-8 section" style="padding: 0rem">
      <div class="grid">
        <label for="receiver" class="col-7">{{ $t("deliver.pieces") }}</label>
        <div class="col-5">{{ receipt.packageNumber }}</div>
        <label for="receiver" class="col-7">{{ $t("deliver.weight") }}</label>
        <div class="col-5">
          {{ entity.weightLbs }} {{ $t("unit.lbs") }}/{{ weightKg }}
          {{ $t("unit.kg") }}
        </div>
        <label for="receiver" class="col-12">GIFT</label>
      </div>
    </div>
    <div class="col-12 section grid">
      <label for="sendername" class="col-4">{{
        $t("deliver.send_from")
      }}</label>
      <div id="sendername" class="col-8">
        {{ receipt.dSenderName || receipt.senderName }}
      </div>
      <div id="senderAddress" class="col-12">{{ senderAddress }}</div>
      <!--
      <div id="senderState" class="col-5" v-if="!receipt.dSenderAddress">{{receipt.senderState}}</div>
      <div id="senderCounty" class="col-7" v-if="!receipt.dSenderAddress">{{receipt.senderCounty}}</div>
      <div id="senderCity" class="col-9" v-if="!receipt.dSenderAddress && receipt.senderCity">{{receipt.senderCity}}</div>
      <div id="senderCity" class="col-9">{{receipt.dSenderAddress || receipt.senderAddress}}</div>
      -->
    </div>
    <div class="col-12 section grid">
      <label for="receiver" class="col-4">{{ $t("deliver.send_to") }}</label>
      <div id="recipientname" class="col-8" style="font-weight: bold">
        {{ receipt.dRecipientName || receipt.recipientName }}
      </div>
      <div id="recipientaddress" class="col-12" style="font-weight: bold">
        {{ recipientAddress }}
      </div>
      <!--
      <div id="recipientProvince" class="col-9" v-if="!receipt.dRecipientAddress">{{receipt.recipientProvince}}</div>
      <div id="recipientDistrict" class="col-9" v-if="!receipt.dRecipientAddress">{{receipt.recipientDistrict}}</div>
      <div id="recipientWard" class="col-9" v-if="!receipt.dRecipientAddress && receipt.recipientWard">{{receipt.recipientWard}}</div>
      <div id="recipientAddress" class="col-9">{{receipt.dRecipientAddress|| receipt.recipientAddress}}</div>
      -->
    </div>
    <label for="receiver" class="col-4">{{ $t("deliver.node") }}</label>
    <div class="col-8">{{ receipt.deliveryNote }}{{ signal }}</div>
    <div class="col-12" style="font-style: italic; font-weight: bold">
      Vui lòng cho người nhận xem hàng nếu được yêu cầu
    </div>
    <div class="col-12">
      {{ receipt.recipientPhone }}
    </div>
    <div
      class="col-12 shpping-label-footer"
      style="font-style: italic; font-size: 0.8rem"
    >
      {{
        $t("shipment.shipping_label_footer", {
          printCopy: (entity.printCounter || 0) + 1,
          printTime: printTime,
        })
      }}
    </div>
  </div>
  <div class="footer" />
</template>
<script>
import ReceiptService from "@/service/ReceiptService";
import Utils from "@/service/Utils";
import { converter } from "@/utils/common";
import { DateTime } from "luxon";
export default {
  components: {},
  props: {
    receipt: {
      type: Object,
      default() {
        return {};
      },
    },
    entity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    let resource = "package|" + this.entity.packageCode;
    resource = process.env.VUE_APP_BASE_URL + "#/" + Utils.toBase64(resource);
    console.log(resource);
    return {
      packageResource: resource,
      qrcodeSize: 100,
      metaItemTable: {
        entityName: "packageItem",
        paginator: {
          enable: false,
        },
        columns: [
          {
            name: "quantity",
            headerStyle: "width:10%; min-width:10rem;",
          },
          {
            name: "name",
            headerStyle: "width:80%; min-width:10rem;",
          },
        ],
      },
    };
  },
  computed: {
    senderAddress() {
      return ReceiptService.getSenderAddress(this.receipt);
    },
    recipientAddress() {
      return ReceiptService.getRecipientAddress(this.receipt);
    },
    weightKg() {
      if (this.entity.weightLbs) {
        return converter.tokg(this.entity.weightLbs);
      }
      return 0;
    },
    signal() {
      return this.receipt.dRecipientAddress ? "F" : "";
    },
    printTime() {
      const dateTime = DateTime.fromJSDate(new Date());
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
  },
  mounted() {
    /*
    if(this.entity.receiptCode && Array.isArray(this.shipment.receipts)) {
      for(let i = 0; i < this.shipment.receipts.length; i++) {
        if (this.shipment.receipts[i].receiptCode == this.entity.receiptCode) {
          this.receipt = this.shipment.receipts[i];
          break;
        }
      }
    }
    */
  },
};
</script>
<style lang="scss" scoped>
.grid {
  margin: 0rem;
}
.logo {
  width: 100px;
  padding-left: 0.5rem;
}
.footer {
  padding-bottom: 0rem;
}
.section {
  border: 1px solid #ced4da;
}
@media print {
  .footer {
    page-break-after: always;
  }
}
/*
.grid > [class*=col] {
  padding: 0;
  border: 1px solid #ced4da;
}
*/
</style>
