<template>
  <div class="shipping-form">
    <hot-table ref="hotTableComponent" 
        :renderAllRows="true"
        :afterLoadData="onAfterLoadData"
        :settings="hotSettings" 
        :rowHeaders="false" 
        :colHeaders="false" 
        :readOnly="true"
        licenseKey="non-commercial-and-evaluation">
    </hot-table>
    <!-- <div class="footer" style="font-style: italic">
      {{ $t("shipment.shipping_form_footer", { printCopy: 1, printTime }) }}
    </div> -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { HotTable } from '@handsontable/vue3';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css';
import TemplateService from "@/service/TemplateService";
import AuthService from '@/service/AuthService';
import Utils from "@/service/Utils";
import { converter } from "@/utils/common";
import consts from "@/utils/consts";
import formatter from '@/utils/formatter';
import { addClassesToRows, alignHeaders } from "@/utils/handsonhooks";
import { handsontablePlugin } from "@/utils/handsontable";
import { DateTime } from "luxon";

import { utils } from "xlsx";
import Handlebars from "handlebars";
import {v4 as uuidv4} from 'uuid';

// register Handsontable's modules
registerAllModules();
handsontablePlugin.registerRenderers();
const registerHandleBarHelpers = () => { 
  // Handlebars.registerHelper("iterate", function (context, field, options) {
  //   var ret: any[] = [];
  //   for (var i = 0, j = context.length; i < j; i++) {
  //     ret.push(options.fn(context[i]));
  //   }

  //   return ret;
  // });
  Handlebars.registerHelper("aggsum", function (context, options) {
    var ret = 0;
    if (Array.isArray(context)) {
      for (var i = 0, j = context.length; i < j; i++) {
        let elmValue = options.fn(context[i]);
        try {
          let value = parseFloat(elmValue);
          ret += value;
        } catch (e) {
          console.error(e);
        }
      }
    }
    return ret;
  });
  Handlebars.registerHelper("mul", function (lvalue, rvalue) {
      let lnumber = parseFloat(lvalue);
      let rnumber = parseFloat(rvalue);
    return !isNaN(lnumber) && !isNaN(rnumber) ? lnumber * rnumber : 0;
  });
  Handlebars.registerHelper("qrcode", function (value) {
    return "#qrcode#" + value;
  });
}


const CHARACTER_WIDTH = 8.5;
export default defineComponent({
  components: { HotTable },
  emits:["afterLoadData"],
  props: {
    receipt: {
      type: Object,
      default() {
        return {};
      },
    },
    entity: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    let resource = "package|" + this.entity.packageCode;
    resource = process.env.VUE_APP_BASE_URL + "#/" + Utils.toBase64(resource);
    return {
      packageResource: resource,
      qrcodeSize: 100,
      hotSettings: {
        widths: [],
        mergeCells: [],
        cell: [],
        customBorders: [],
        rowHeaders: [],
        rowHeights: [],
        headers: [],
        data: [],
      }
    };
  },    
  computed: {
    packageContent() {
      //20231215 - Hide content
      // return this.entity.packageItems
      //   .map((pkg) => pkg.quantity + " " + pkg.name)
      //   .join(", ");
      return "";
    },
    weightKg() {
      if (this.entity.weightLbs) {
        return converter.tokg(this.entity.weightLbs);
      }
      return 0;
    },
    printTime() {
      const dateTime = DateTime.fromJSDate(new Date());
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
  },
  methods: {
    onAfterGetColHeader(that, column, TH) {
      alignHeaders(that, column, TH);
    },
    onBeforeRenderer(TD, row, column, prop, value, cellProperties) {
      addClassesToRows(TD, row, column, prop, value, cellProperties);
    },
    onAfterLoadData() {
      this.$emit("afterLoadData");
    },
    renderEmsCode(code) {
      if (!code || code.length == 0) {
        return "00000";
      }
      let res = "" + (code || "");
      while (res.length < 5) {
        res = "0" + res;
      }
      return res;
    },
    createSubmission() {
      const senderAddress = [];
      const recipientAddress = [];
      let receipt = this.receipt || {};
      if (receipt.senderState) {
        senderAddress.push(receipt.senderState);
      }
      if (receipt.senderCounty) {
        senderAddress.push(receipt.senderCounty);
      }
      if (receipt.senderCity) {
        senderAddress.push(receipt.senderCity);
      }
      if (receipt.senderAddress) {
        senderAddress.push(receipt.senderAddress);
      }

      if (receipt.recipientAddress) {
        recipientAddress.push(receipt.recipientAddress);
      }
      if (receipt.recipientWard) {
        recipientAddress.push(receipt.recipientWard);
      }
      if (receipt.recipientDistrict) {
        recipientAddress.push(receipt.recipientDistrict);
      }
      if (receipt.recipienProvince) {
        recipientAddress.push(receipt.recipienProvince);
      }
      
      let submission = {
        _id: uuidv4(),
        receipt: this.receipt,
        data: {
          packageCode: this.entity?.packageCode,
          packageEmsCode: this.renderEmsCode(this.entity?.packageEmsCode),
          weightLbs: this.entity?.weight,
          weightKg: converter.tokg(this.entity?.weight),
          receipt: {
            data: {
              referenceCode: receipt.referenceCode,
              agentCode: receipt.agentCode,
              sender: {
                data: {
                  contactName: receipt.senderName,
                  contactPhone: receipt.senderPhone,
                  address: senderAddress.join(', ')
                }
              },
              recipient: {
                data: {
                  contactName: receipt.recipientName,
                  contactPhone: receipt.recipientPhone,
                  address: recipientAddress.join(', ')
                }
              }
            }
          },
          manifestItems: this.entity.itemDtos || []
        },
        access: [],
        created: null,
        form: "",
        metadata: {},
      };
      if (this.receipt.packageNumber == 1) {
        //submission.created = formatter.formatDate(this.receipt.createdDate, "dd/MM/yyyy");
        submission.created = this.receipt.createdDate;
        if (this.entity.itemDtos == null) {
          submission.data.manifestItems = this.receipt.packageItemDtos;
          if (submission.data.manifestItems) {
            submission.data.manifestItems.forEach(item => { item.description = { data: { name: item.name?.trim() } } })
          }
        }
        if (this.entity.weightLbs == 0) {
          submission.data.weightLbs = this.receipt.weight;
          submission.data.weightKg = converter.tokg(this.receipt.weight);
        }
      }
      return submission;
    },
    bindData(sheet) { 
      const userDetail = AuthService.currentUser || {};
      let submission = this.createSubmission();
      let separator = `__${submission._id}__`;
      let header = 1, range;
      if (sheet["!ref"]) {
        range = utils.decode_range(sheet["!ref"]);
      }
      const parserOptions = {
        cellDates: true,
        UTC: true,
        header,
        range
      };
      const sheetData = utils.sheet_to_json(sheet, parserOptions);
      const data = [];
      const styledCells = [];
      registerHandleBarHelpers();
      Handlebars.registerHelper("iterate", function(context, options) {
        var ret = [];
        for (var i = 0, j = context.length; i < j; i++) {
          ret.push(options.fn(context[i]));
        }

        return ret.join(separator);
      });
      Handlebars.registerHelper("iterate2", function(context, part, total, options) {
        var ret = [];
        //Chi hien thi 1 phan {part}/{total} record trong context
        if (part > 0 && total >= part) {
          let size = Math.ceil(context.length / total);
          let start = size * (part - 1);
          let end = part < total ? start + size : context.length;
          for (var i = start; i < end; i++) {
            ret.push(options.fn(context[i]));
          }
        } else {
          for (var j = 0; j < context.length; j++) {
            ret.push(options.fn(context[j]));
          }
        }
        

        return ret.join(separator);
      });
      //const context = { user: userDetail, ...submission, created: formatter.formatDate(submission.created, "DD/MM/yyyy hh:mm:ss") };
      const context = {
        user: userDetail, ...submission,
        createdDate: formatter.formatDate(submission.created, consts.FORMAT_DATE_VN),
        createdTime: formatter.formatDate(submission.created, consts.FORMAT_TIME)
      };
      let fistRowLength = 0;
      for (let rowInd = 0; rowInd < sheetData.length; rowInd++) {
        let sheetRow = sheetData[rowInd];
        //adjust first row
        if (rowInd == 0) {
          fistRowLength = sheetRow.length;
        } else if (sheetRow.length > fistRowLength) {
          for (let i = fistRowLength; i < sheetRow.length; i++) {
            data[0].push("");
          }
          fistRowLength = data[0].length;
        }
        /*
        * Lay gia tri thuoc dong dang xu ly.
        * Khi render list of item, 1 so dong phia sau co the dc tao ra o loop truoc
        */
        let row = data[rowInd] || [];
        data[rowInd] = row;
        for (let col = 0; col < sheetRow.length; col++) {
          let cell = sheetRow[col];
          if (typeof cell == 'string') {
            try {
              const template = Handlebars.compile(cell);
              //var compiled = _template(cell);
              let cellValue = template(context);
              if (typeof cellValue === 'string') {
                if (cellValue.startsWith("#qrcode#")) {
                  let cellSetting = {
                    row: rowInd,
                    col,
                    renderer: 'qrcodeRenderer',
                    value: cellValue.substring("#qrcode#".length),
                    resource: "package"
                  };
                  styledCells.push(cellSetting);
                } else {
                  try {
                    let parts = cellValue.split(separator);
                    for (let j = 0; j < parts.length; j++) {
                      let rowValue = data[rowInd + j] || [];
                      data[rowInd + j] = rowValue;
                      rowValue[col] = parts[j];
                    }
                  } catch (e) {
                    row[col] = cellValue;
                  }
                }
              } else if (cellValue != undefined && cellValue != null) {
                row[col] = cellValue;
              }
            } catch (e) {
              console.log(e)
              row[col] = row[col] || cell;
            }
          } else {
            row[col] = row[col] || cell;
          }
        }
      }
      return {data, styledCells};
    },
    parseMetadata(sheet, styles) { 
      let widths = [];
      let mergeCells = [];
      let styledCells = [];
      if (sheet["!ref"] && sheet["!cols"]) {
        let range = utils.decode_range(sheet["!ref"]);
        for (let c = 0; c <= range.e.c; c++) {
          let colStyle = sheet["!cols"][c];
          if (colStyle?.wch) {
            widths.push(colStyle.wch * CHARACTER_WIDTH);
          } else if (colStyle?.wpx) {
            widths.push(colStyle.wpx);
          } else {
            console.log("missing colstyle at col: ", c);
          }
        }
      
        if (sheet["!merges"]) {
          for (let ind = 0; ind < sheet["!merges"].length; ind++) {
            let range = sheet["!merges"][ind];
            let mergeDetail = {
              row: range.s.r,
              col: range.s.c,
              rowspan: range.e.r - range.s.r + 1,
              colspan: range.e.c - range.s.c + 1
            }
            mergeCells.push(mergeDetail);
          }
        }
        
        for (let key in sheet) {
          if (key.startsWith("!")) continue;
          let cell = sheet[key], range;
          if ((cell.s || cell.h)&& (range = utils.decode_range(key))) {
            if (range) {
              let cellSetting = {
                row: range.s.r,
                col: range.s.c
                //comment: cell.s
              };
              if (cell.s) {
                let classNames = [];
                if (cell.s.fontInd && styles?.fonts.length > cell.s.fontInd) {
                  let cellFont = styles.fonts[cell.s.fontInd];
                  cellSetting.font = cellFont;
                  cellSetting.renderer = 'xlsxStylesRenderer';
                  if (cellFont?.name == 'Wingdings 2') {
                    classNames.push("wingdings2");
                  }
                }
                if (cell.s.valign) {
                  classNames.push("ht" + cell.s.valign);
                }
                if (cell.s.halign) {
                  classNames.push("ht" + cell.s.halign);
                }
                if (classNames.length > 0) {
                  cellSetting.className = classNames.join(" ");
                }
              } else if (cell.h) {
                cellSetting.renderer = 'imageRenderer';
                cellSetting.size = cell.size;
                cellSetting.base64 = cell.h;
              }
              styledCells.push(cellSetting);
            }
          }
        }
      }
      let rowHeights = sheet["!rows"]?.map((row) => row.hpx)
      return {
        widths,
        rowHeights,
        mergeCells,
        styledCells
      }
    },
  },

  mounted() {
    TemplateService.getShippingFormEms().then(xlsxTemplates => { 
      if (Array.isArray(xlsxTemplates) && xlsxTemplates.length > 0) {
        let xlsxTemplate = xlsxTemplates[0];
        let sheetName = xlsxTemplate.SheetNames[0];
        let sheet = xlsxTemplate.Sheets[sheetName];
        let styles = xlsxTemplate.Styles;
        let customBorders = sheet["!borders"];
        let meta = this.parseMetadata(sheet, styles);
        let bindedData = this.bindData(sheet);
        let hotSettings = {
          customBorders,
          colWidths: meta.widths,
          rowHeights: meta.rowHeights,
          mergeCells: meta.mergeCells,
          cell: meta.styledCells.concat(bindedData.styledCells),
          data: bindedData.data
        };
        this.$refs.hotTableComponent.hotInstance.updateSettings(hotSettings, true);
        this.hotSettings = hotSettings;
      }
    });
  },
});
</script>
<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-left: 5px;
}
.grid {
  margin: 0rem;
}
.logo {
  width: 100px;
  padding-left: 0.5rem;
}
.title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  width: 100%;
  margin-top: 5px;
}
.address {
  width: 100%;
  padding-left: 0.5rem;
}
.footer {
  padding-bottom: 0rem;
}
.section {
  border: 1px solid #ced4da;
}
.qrcode {
  width: 30%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media print {
  .footer {
    page-break-after: always;
  }
}
/*
.grid > [class*=col] {
  padding: 0;
  border: 1px solid #ced4da;
}
*/
</style>
